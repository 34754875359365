*
{
	-webkit-appearance: none;
	outline: none;
	outline-style: none !important;
}

body
{
	background-color: #F6F6F6;
	/* max-width: 100vw; */
	overflow-x: hidden;
}

nav
{
	max-width: 100vw !important;
	border-bottom: 2px solid #F6F6F6;
}

.content-1 > p
{
	text-align: center;
	padding: 20px;
	background-color: #AD8DC0;

	border-bottom: 1px solid #FFF;
}

.content-2 > p
{
	text-align: center;
	padding: 20px;
	background-color: #FFAA72;

	border-bottom: 1px solid #FFF;
}

.headingContent2
{
	text-align: center;
	padding: 10px;
	background-color: #FFAA72;
	margin: 0;

	border-bottom: 1px solid #FFF;
}

.content-3 > p
{
	text-align: center;
	padding: 20px;
	background-color: #68ACE0;

	border-bottom: 1px solid #FFF;
}

.headingContent3
{
	text-align: center;
	padding: 10px;
	background-color: #68ACE0;
	margin: 0;
	border-bottom: 1px solid #FFF;
}

.content3Table
{
	background-color: #FFAA72 !important;
	margin-top: 60px !important;
}

.content4Table
{
	background-color: #AD8DC0 !important;
}

.headingContent4
{
	text-align: center;
	padding: 10px;
	background-color: #AD8DC0;
	margin: 0;
	border-bottom: 1px solid #FFF;
}

.content-5 > p
{
	text-align: center;
	padding: 20px;
	background-color: #FFAA72;

	border-bottom: 1px solid #FFF;
}

.headingContent5
{
	text-align: center;
	padding: 10px;
	background-color: #FFAA72;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	margin: 0 !important;

	border-bottom: 1px solid #FFF;
}

.col-sm-4 > img
{
	height: 150px;
	margin: auto;
	max-width: 500px;
}

.col-sm-4 > h4
{
	text-align: center;
	padding: 20px;
	color: #000;
}

.row
{
	padding-top: 100px;
	padding-bottom: 100px;
	max-width: 100vw;
	margin: 0 !important;
}

.content-1
{
	width: 100vw;
	margin: auto;
	max-width: 100vw;
	background-color: #C7C7F2;
}



.content-2
{
	width: 100vw;
	margin: auto;
	max-width: 100vw;
	background-color: #FFCE72;
}


.col-sm-7
{
	margin: auto;
}

.col-sm-5  > table
{
	border-radius: 15px;
	background-color: #FFAA72;
	height: 100%;
	margin: auto;
	max-height: 400px;
	max-width: 500px;
}

.content-3
{
	width: 100vw;
	margin: auto;
	max-width: 100vw;
	background-color: #A5D5F9;
}

.col-sm-6  > table
{
	margin: auto;
	border-radius: 15px;
	background-color: #68ACE0;
	padding: 50px;
	margin-bottom: 40px;
	height: 100%;
	width: 80%;
	-webkit-box-shadow: -19px 19px 40px -21px rgba(0,0,0,0.75);
	-moz-box-shadow: -19px 19px 40px -21px rgba(0,0,0,0.75);
	box-shadow: -19px 19px 40px -21px rgba(0,0,0,0.75);
}

.financeTable
{
	margin: auto;
	border-radius: 15px;
	background-color: #68ACE0;
	padding: 50px;
	margin-bottom: 40px;
	height: 100%;
}

.content-4
{
	width: 100vw;
	margin: auto;
	max-width: 100vw;
	background-color: #C7C7F2;
}


.content-5
{
	width: 100vw;
	margin: auto;
	max-width: 100vw;
	background-color: #FFCE72;
}


.col-sm-6
{
	margin: auto;
}

.financeTable
{
	width: 400px;
}

.contactForm
{
	width: 80% !important;
	max-width: 1000px !important;
	margin: auto;
}

#form-ultra
{
	background-color: #FFF;
	padding: 30px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	-webkit-box-shadow: 17px 19px 40px -21px rgba(0,0,0,0.75);
	-moz-box-shadow: 17px 19px 40px -21px rgba(0,0,0,0.75);
	box-shadow: 17px 19px 40px -21px rgba(0,0,0,0.75);
}

#form-ultra > button
{
	background-color: #FFAA72;
	width: 100px;
	padding: 10px;
	border: 1px solid #FFAA72;
	border-radius: 15px;
	color: #FFF;
	margin: auto;
}

#form-ultra > button:hover
{
	background-color: #FFF;
	color: #FFAA72;
}

.coverPage
{
	background-color: #A5D5F9;
	padding: 10px;
	height: 70vw;
	max-height: 70vh;
	width: 100vw;
	max-width: 100vw;
	background-image: url(../../icons/bambini_svg/homeScreen.svg);
	background-position: top center;
	background-repeat: no-repeat;
	margin: auto;
}

.container-0
{
	height: 100vh;
	display: flex;
	justify-content: center;
	background-color: #A5D5F9;
}

.container-0 > div
{
	align-self: center;
}

.moreInfo
{
	width: 100vw;
	text-align: center;
	margin-top: 20px;
}

.moreInfo > a
{

	padding: 10px 40px;
	border: 1px solid #A5D5F9;
	border-radius: 15px;
}

img
{
	max-height: 600px;
}

nav
{
	background-color: #A5D5F9;
	color: #FFF;
	font-size: 20px;
}

.beleidDiv
{
	width: 100%;
	text-align: center;
}

.indexBtn
{
	background-color: #68ACE0;
	padding: 10px 30px;
	border: 2px solid #68ACE0;
	border-radius: 15px;
	color: #000;
	margin: auto;
}

.indexBtn:hover
{
	background-color: #A5D5F9;
}

.geldBtn
{
	background-color: #AD8DC0;
	padding: 10px 30px;
	border: 2px solid #AD8DC0;
	border-radius: 15px;
	color: #000;
	margin: auto;
}

.geldBtn:hover
{
	background-color: #C7C7F2;
}

.socialMedia
{
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
	background-color: #A5D5F9;
	font-size: 20px;
	padding-bottom: 20px;
}

footer
{
	background-color: #FFAA72;
	height: 50px;
	color: #FFF;
}

.footerCopy
{
	background-color: #FFAA72;
	padding: 15px;
	text-align: center;
	margin: 0;
	color: #000;

}

.footerLink
{
	padding: 15px !important;
}

small
{
	color: #e74c3c;
	visibility: hidden;
}

.navbar-fixed-top.scrolled
{
	background-color: #000 !important;
	transition: background-color 200ms linear;
}

.footerLinks
{
	text-align: center;
	background-color: #FFAA72;
	color: #000;
}

.form-control:focus
{
	border-color: #FFAA72 !important;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 170, 114, 1) !important;
}


@media screen and (max-width: 600px)
{
	.socialMedia
	{
		font-size: 18px;
	}
}

.navbar-default{
  transition:500ms ease;
  background:transparent;
}
.navbar-default.scrolled{
  background:#000;
}
